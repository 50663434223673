import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import Router, { useRouter } from 'next/router'

import { Button, Card, Col, Divider, notification, Row } from 'antd'
import { Form, Formik } from 'formik'
import ApiCall from 'services/ApiCall'

import useGetVersion from 'data/Version/useGetVersion'

import { FInput } from 'components/fields'
import Text from 'components/Typography/Text'
import parseJWT from 'helpers/parseJWT'
import TwoFactorAuthLogin from 'views/Login/TwoFactorAuthLogin'

import packageJson from '../../../package.json'

interface LoginAttributes {
  username: string
  password: string
}

function Login() {
  const router = useRouter()
  const { t } = useTranslation(['common'])

  const { data: backendVersion } = useGetVersion()

  const postLogin = useMutation(
    (data: LoginAttributes) => ApiCall.login(data),
    {
      onSuccess: ({ data }, { password }) => {
        if (data.user_id) {
          setLoginData({
            password,
            user_id: data?.user_id,
          })
        }
      },
    },
  )

  const [loginData, setLoginData] = useState({
    password: '',
    user_id: '',
  })

  useEffect(() => {
    if (router.isReady) {
      const refreshToken = localStorage.getItem('refresh-token')
      const parsedJwt = parseJWT(refreshToken)
      const keyPin = localStorage.getItem('key-pin')
      if (parsedJwt && keyPin) {
        if (router.query?.prevPage) {
          Router.replace(router.query.prevPage as string)
        } else {
          Router.replace('/home')
        }
      }
    }
  }, [router.isReady])

  // This useEffect is temporary, and soon will be deleted after
  // the issue in dev7 is fixed
  useEffect(() => {
    if (router.query?.error) {
      notification.error({
        message: t(`common:${router.query.error}`),
      })
    }
  }, [])

  if (loginData?.user_id) {
    return (
      <TwoFactorAuthLogin
        password={loginData.password}
        userId={loginData.user_id}
      />
    )
  }
  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
        db: process.env.DB_NAME || 'new_dev',
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const res = await postLogin.mutateAsync(values)

          if (res.data.error) {
            notification.error({
              message: res.data.error?.data?.message,
            })
            return
          }

          if (res.data.user_id) {
            return
          }

          notification.success({
            message: t('common:login_successfully'),
          })

          const { access_token, refresh_token, name, user_type } = res.data
          localStorage.setItem('token-ldx', access_token)
          localStorage.setItem('key-pin', res.headers.kid)
          localStorage.setItem('refresh-token', refresh_token)
          localStorage.setItem('name', name)
          localStorage.setItem('current_user_type', user_type)
          if (router.query?.prevPage) {
            Router.replace(router.query.prevPage as string)
          } else {
            Router.replace('/home')
          }
        } catch (error) {
          notification.error({
            message: error.message,
          })
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting } = formikProps

        return (
          <Form className="h-full">
            <Row
              justify="center"
              align="middle"
              style={{
                minHeight: `calc(100vh - 50px)`,
              }}
            >
              <Col sm={12} xs={24} md={9}>
                <Card>
                  <FInput name="username" title={t('common:user_id')} />

                  <div className="my-3">
                    <FInput
                      type="password"
                      name="password"
                      title={t('common:password')}
                    />
                  </div>

                  <div className="text-center">
                    <Button
                      type="primary"
                      block
                      loading={isSubmitting}
                      htmlType="submit"
                      className="w-1/2"
                    >
                      {t('common:login')}
                    </Button>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row
              style={{
                padding: 16,
                borderTop: '1px solid #003288',
              }}
              justify="center"
            >
              <Col>
                <Text>{`${t('version_be')}: ${backendVersion || '-'}`}</Text>
                <Divider type="vertical" className="h-full"></Divider>
                <Text>{`${t('version_fe')}: v${packageJson.version}`}</Text>
              </Col>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Login
