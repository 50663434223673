import { useQuery } from 'react-query'

import ApiCall from 'services/ApiCall'

const useGetVersion = () => {
  return useQuery(
    ['GetBackendVersion'],
    async () => {
      const formData = new FormData()
      const res = await ApiCall.api.post('/ldx_version/', formData)

      return res.data?.result ?? ''
    },
    {
      staleTime: 5000 * 60,
    },
  )
}

export default useGetVersion
