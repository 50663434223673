import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useRouter } from 'next/router'

import { Button, Card, Col, Form, Input, notification, Row } from 'antd'
import ApiCall from 'services/ApiCall'

type Props = {
  password: string
  userId: string
}
const TwoFactorAuthLogin = ({ password, userId }: Props): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const [isSubmitting, setSubmitting] = useState(false)

  const handleSubmitCode = async (values) => {
    setSubmitting(true)
    try {
      const formData = new FormData()
      formData.append('password', password)
      formData.append('user_id', userId)
      formData.append('totp', values.code)
      const res = await ApiCall.api.post('/totp_auth', formData)

      const { access_token, refresh_token, name, user_type } = res.data
      localStorage.setItem('token-ldx', access_token)
      localStorage.setItem('key-pin', res.headers.kid)
      localStorage.setItem('refresh-token', refresh_token)
      localStorage.setItem('name', name)
      localStorage.setItem('current_user_type', user_type)
      if (router.query?.prevPage) {
        router.replace(router.query.prevPage as string)
      } else {
        router.replace('/home')
      }
    } catch (error) {
      notification.error({
        message: error?.message,
      })
    } finally {
      setSubmitting(false)
    }
  }
  return (
    <Row justify="center" align="middle" className="h-full">
      <Col sm={12} xs={24} md={9}>
        <Card title={t('two_factor_authentication')}>
          <Form layout="vertical" colon={false} onFinish={handleSubmitCode}>
            <Form.Item
              name="code"
              label={t('common:authentication_code')}
              extra={t('common:authentication_two_factor_help')}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input type="number" placeholder="e.g. 123456" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                block
                loading={isSubmitting}
                htmlType="submit"
                className="w-1/2"
              >
                {t('common:login')}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  )
}

export default TwoFactorAuthLogin
